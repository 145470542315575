<template>
    <div class="main">
        <div class="main1">
            <div class="topInputBox">
                <input class="input" placeholder="  请输入账号">
                <button class="conform">确定</button>
            </div>

            <div class="content">
                <div class="title">“TA聊”VIP服务</div>
                <div class="logoRight"></div>
                <div class="a1">{{ a[index] }}</div>
                <div class="b1">{{ b[index] }}</div>
            </div>

            <div class="items">
                <div class="item">
                    <div class="tt">首月优惠</div>
                    <div class="duration">连续包月</div>
                    <div class="money">￥30</div>
                    <div class="tip" style="color: #E4BA6E;">首月18元</div>
                </div>
                <div class="item">
                    <div class="tt">最多人选择</div>
                    <div class="duration">连续包季</div>
                    <div class="money">￥68</div>
                    <div class="tip">节省40%</div>
                </div>
                <div class="item">
                    <div class="tt">最优惠</div>
                    <div class="duration">连续包年</div>
                    <div class="money">￥228</div>
                    <div class="tip">节省59%</div>
                </div>
            </div>

            <div class="toBuy">开通VIP</div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                index: 0,
                a: ['联系更多人', '便捷翻译', '隐身在线状态', '免广告', '更多会员特权'],
                b: ['每天私信预伴的次数是其他人的2倍', '开启自动翻译，轻松享受无限翻译', '打开隐身模式，不想聊天时就减少打扰', '免除广告，刷动态更流畅', '专属装扮，更多曝光和人气'],
            }
        },
        methods: {
            timeCB() {
                this.index ++
                if (this.index == 5) {
                    this.index = 0
                }
            }
        },
        mounted() {
            setInterval(this.timeCB, 2000)
        },
    }
</script>

<style>
.main {
    display: flex;
    flex-direction: column;
    width: 375px;
    height: 812px;
}

.main1 {
    display: flex;
    flex-direction: column;
    width: 375px;
    height: 577px;
    background: url('../../assets/vip/bbg.png');
}

.topInputBox {
    width: 353px;
    height: 45px;
    display: flex;
    justify-content: space-around;
    margin-top: 39px;
    margin-left: 12px;
}

.topInputBox .input {
    width: 268.5px;
    height: 45px;
    background: #FFFFFF;
    border-radius: 10px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #CCCCCC;
}

.topInputBox .conform {
    width: 74px;
    height: 45px;
    background: #FC3569;
    border-radius: 10px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    border: none;
}

.content {
    width: 354px;
    height: 187.5px;
    background: linear-gradient(77deg, #E9AD60 0%, #ECD191 100%);
    border-radius: 10px;
    margin-top: 17.5px;
    margin-left: 12px;
    background: url('../../assets/vip/tequan.png');
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
}

.content .title {
    height: 20px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFCF5;
    margin-left: 12px;
    margin-top: 15px
}

.content .logoRight {
    width: 112.5px;
    height: 39.5px;
    background: #2C2117;
    /* border-radius: 0px 20px 0px 20px; */
    background: url('../../assets/vip/right_logo.png');
    background-size: 100% 100%;
    align-self: flex-end;
    margin-top: -35px;
}

.content .a1 {
    height: 20px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #FFFFFF;
    margin-left: 8px;
    margin-top: 33.5px
}

.content .b1 {
    height: 15px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    margin-left: 8px;
    margin-top: 18.5px
}

.items {
    width: 353px;
    height: 158px;
    display: flex;
    justify-content: space-around;
    margin-top: 39px;
    margin-left: 12px;
}

.items .item {
    width: 106px;
    height: 158px;
    border: 1px solid #E8B24E;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
}

.items .item .tt {
    height: 34.5px;
    background: #EFBE63;
    border-radius: 6px 6px 0px 0px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    text-align: center;
    line-height: 34.5px;
    color: #FFFFFF;
    margin-top: -1px;
}

.items .item .duration {
    height: 15px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin-top: 16.5px;
    margin-left: 24px;
}

.items .item .money {
    width: 106px;
    height: 22.5px;
    font-size: 22.5px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    text-align: center;
    margin-top: 18px;
}

.items .item .tip {
    width: 106px;
    height: 14.5px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    text-align: center;
    margin-top: 19.5px;
}

.toBuy {
    width: 337px;
    height: 50px;
    background: linear-gradient(-88deg, #FB9162, #F3B121);
    border-radius: 25px;
    margin-top: 44.5px;
    margin-left: 19px;

    font-size: 18.5px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
    line-height: 50px;
}
</style>